import {
  CUSTOMER, STYLIST_SETTINGS, SERVICE_LIST, CALENDAR, APPOINTMENT, REVIEW, DASHBOARD,
} from '@/router/routes/routes-names'

export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: DASHBOARD,
  },
  {
    title: 'Calendar',
    icon: 'CalendarIcon',
    route: CALENDAR,
  },
  {
    title: 'Appointments',
    icon: 'BookOpenIcon',
    route: APPOINTMENT,
  },
  {
    title: 'Customers',
    icon: 'UserIcon',
    route: CUSTOMER,
  },
  {
    title: 'Reviews',
    tagVariant: 'warning',
    icon: 'HeartIcon',
    route: REVIEW,
  },
  {
    title: 'Services',
    icon: 'ListIcon',
    route: SERVICE_LIST,
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: STYLIST_SETTINGS,
  },
]
